<template>
  <div class="widget" v-show="show">
    <!-- <div class="widget-navbar">
      <p>Faça uma doação</p>
      <a v-show="closeModal" @click="show = false"
        ><i class="fal fa-times"></i
      ></a>
    </div>-->
    <div class="widget-slick" @scroll="checkScroll">
      <button class="prev" @click="prev" v-if="donationProducts && donationProducts.length > 1 && checkoutSimplifiedOpenValue">
        <i class="fas fa-chevron-left"></i>
      </button>
      <button class="next" @click="next" v-if="donationProducts && donationProducts.length > 1 && checkoutSimplifiedOpenValue">
        <i class="fas fa-chevron-right"></i>
      </button>
      <template v-for="donationProduct in donationProducts">
        <div
          class="widget-header"
          :style="`background-image: url(${donationProduct.file.path})`"
          :key="donationProduct.id"
          :data-id="donationProduct.id"
        >
          <!-- <div class="widget-header-logo">
            <img :src="landingPageOptions.logo.path" alt />
          </div> -->
          <div></div>
          <div class="widget-header-info">
            <div v-if="!otherValue">
              <h4>{{ donationProduct.suggestionValue }}</h4>
              <div class="widget-header-title">
                <p>{{ donationProduct.name }}</p>
                <!-- <span v-if="donationProduct.description">
                  <i class="fal fa-plus-circle"></i>
                  <i class="fal fa-minus-circle"></i>
                </span>-->
              </div>
              <span
                class="widget-header-description active"
                v-if="donationProduct.description"
              >{{ donationProduct.description }}</span>
            </div>
            <div v-else-if="otherValue && checkoutSimplifiedOpenValue">
              <div class="widget-header-title">
                <p>{{ donationProduct.name }}</p>
              </div>
              <span
                class="widget-header-description active"
                v-if="donationProduct.description"
              >{{ donationProduct.description }}</span>
            </div>
            <div class="widget-header-info" v-else-if="otherValue && !checkoutSimplifiedOpenValue">
              <h4>Escolha outro valor</h4>
              <span class="widget-header-description active">
                Escolha o valor que deseja doar.
              </span>
            </div>
          </div>
        </div>
      </template>
    </div>
   <div class="widget-buttons" v-if="!checkoutSimplifiedOpenValue">
     <button @click="prevButtons()">
       <i class="fal fa-chevron-left"></i>
     </button>
    <div class="widget-values" v-show="step !== 3">
      <template v-for="(donationProduct, index) in donationProducts">
        <div :key="donationProduct.id">
          <button
            @click="cartDonationValue(donationProduct, index)"
            :class="{
              active: itemSelected.id == donationProduct.id,
            }"
            :data-value-id="donationProduct.id"
          >
            <div
            @click="$gtag('send', 'event', 'Botão com o valor a doar', 'Botão com o valor a doar', donationProduct.fixedValue)"
              v-if="
                donationProduct.fixedValue != null &&
                donationProduct.fixedValue != 0 &&
                !donationProduct.openValue
              "
            >{{ donationProduct.fixedValue | FormatMoney }}</div>
            <div
            @click="$gtag('send', 'event', 'Botão com o valor a doar', 'Botão com o valor a doar', donationProduct.suggestionValue)"
              v-else-if="
                donationProductValue(donationProduct.suggestionValue) != '' &&
                !donationProduct.openValue
              "
            >
              {{
                donationProductValue(donationProduct.suggestionValue)
                  | FormatMoney
              }}
            </div>
            <div 
              @click="$gtag('send', 'event', 'Botão com o valor a doar', 'Botão com o valor a doar', 'Outros Valores');
              selectOtherValue()" 
              v-else>Outros valores</div>
          </button>
        </div>
      </template>
    </div>
    <button @click="nextButtons()">
      <i class="fal fa-chevron-right"></i>
    </button>
   </div>
    <div class="otherInput" v-if="otherValue">
      <label>Informe o valor da doação</label>
      <div class="widget-header-input">
        <InputMoney
            v-if="!btnOtherValue"
            v-model="cartItem.itemValue"
            :precision="0"
            suffix=",⁰⁰"
            :prefix="R$"
            ref="toInputMoney"
            :pattern="'\\d*'"
            :autoFocusOnValidationFail="false"
            invalidInputMessage="Preencha o valor da doação"
            @blur="
              setFocused($event, false);
            "
            @focus="setFocused($event, true)"
          />
      </div>
    </div>
    <div class="widget-content">
      <Payment
        v-show="step === 1"
        :payments="payments"
        :payment="payment"
        @onSelectPayment="selectPayment($event)"
        @onContinue="goToForm($event)"
      />
      <Form
        v-show="step === 2"
        :payment="payment"
        :fields="fields"
        @onContinue="goToForm($event)"
        @onChangePayment="payment = $event"
        :cartItem="cartItem"
        :campaignLandingPageConfig="campaignLandingPageConfig"
        :project="project"
        v-on:minimumPeriodDonationValue="GetMinimumPeriodDonationValue($event)"
      ></Form>
      <Success v-show="step === 3" :payment="payment"></Success>
      <div class="widget-footer">
        <span>©{{ new Date().getFullYear() }} • AUC</span>
        <span>
          <a @click="showPolicyTerms = true">Política de Privacidade</a> e
          <a @click="showServiceTerms = true">Termos de Uso</a>.
        </span>
      </div>
    </div>
    <div class="widget-loading" v-show="loading"></div>
    <div class="widget-snackbar" v-show="error">
      <i class="fas fa-exclamation-triangle"></i>
      <p class="message">Erro de conexão. Por favor tente novamente mais tarde.</p>
      <a @click="error = false">
        <i class="fal fa-times"></i>
      </a>
    </div>
    <UseTermsModal v-model="showServiceTerms" @close="showServiceTerms = false"></UseTermsModal>
    <PrivacyPolicyTermsModal v-model="showPolicyTerms" @close="showPolicyTerms = false"></PrivacyPolicyTermsModal>
  </div>
</template>

<script>
/* 
Modos de pagamento
Valores + opção de outro valor
Campos opcionais do cartão de crédito 
  CPF
  Estado
  Nome no cartão
ID da instituição
Se eh um modal

?payments=card,barcode,apple,google&values=10,50,100,500,-1&fields=name,email,cpf,state,cardname&id=1&type=modal
*/
import Payment from "./Payment.vue";
import Form from "./Form.vue";
import Success from "./Success.vue";
import CartItem from "@/scripts/models/cartItem.model";
import Project from "@/scripts/models/project.model";
import DonationProduct from "@/scripts/models/donationProduct.model";
import ProjectService from "@/scripts/services/project.service";
import UseTermsModal from "@/components/UseTermsModal.vue";
import PrivacyPolicyTermsModal from "@/components/PrivacyPolicyTermsModal.vue";
import CommonHelper from "@/scripts/helpers/common.helper";
import PaymentMethod from '../../../../scripts/models/enums/paymentMethod.enum';

export default {
  components: {
    Payment,
    Form,
    Success,
    UseTermsModal,
    PrivacyPolicyTermsModal,
  },
  props: ['campaignLandingPageConfig', 'landingPageOptions', 'projectId'],
  data() {
    return {
      projectService: new ProjectService(),
      step: 1,
      showServiceTerms: false,
      showPolicyTerms: false,
      payments: [],
      show: true,
      closeModal: false,
      loading: false,
      error: false,
      fields: [],
      project: new Project(),
      cartItem: new CartItem(),
      donationProducts: [],
      otherValue: false,
      btnOtherValue: false,
      itemSelected: new DonationProduct(),
      payment: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      other: [{ id: 1, type: "other", file: { path: "" } }],
      timer: null,
      minimumPeriodDonationValue: 0,
      CommonHelper: CommonHelper,
      checkoutSimplifiedOpenValue: false,
    };
  },
  watch: {
    campaignLandingPageConfig() {
      this.payments = this.campaignLandingPageConfig.campaign.paymentMethodList;
    }
  },
  created() {
    if (this.campaignLandingPageConfig) {
      this.cartItem.project = this.project;
      this.payments = this.campaignLandingPageConfig.campaign.paymentMethodList;
      this.checkoutSimplifiedOpenValue = this.campaignLandingPageConfig.campaign.checkoutSimplifiedOpenValue;
      this.loadDonationProducts();
    }
  },
  methods: {
    CheckMinimumValue() {
      if (this.cartItem.itemValue < this.minimumPeriodDonationValue) {
        this.CommonHelper.swal(
          "Desculpe, você está doando um valor inferior ao mínimo permitido nessa campanha"
        );
        //this.cartItem.itemValue = this.minimumPeriodDonationValue;
      }
    },
    GetMinimumPeriodDonationValue(value) {
      this.minimumPeriodDonationValue = value;
    },
    loadDonationProducts() {
      if(!this.projectId)
        this.projectService.listAllDonationProductsByCampaignId(this.loadDonationProducts_Callback, this.campaignLandingPageConfig.campaignId);
      else
        this.projectService.listDonationProductByProjectId(this.projectId).then(this.loadDonationProducts_Callback)
    },
    loadDonationProducts_Callback(data) {
      this.donationProducts = data.filter(x => x.active);
      // Ordena os produtos de doação por valor sugerido de forma decrescente
      if (this.campaignLandingPageConfig.campaignId === 651){
        // Retira todos os objetos openValue = true e os insere no fim depois da ordenação
        const openValues = this.donationProducts.filter(x => x.openValue);
        this.donationProducts = this.donationProducts.filter(x => !x.openValue);
        this.donationProducts = this.donationProducts.sort((a, b) => {
          return b.fixedValue - a.fixedValue;
        });
        this.donationProducts = this.donationProducts.concat(openValues);
      }
      console.log(this.donationProducts);
      this.cartDonationValue(this.donationProducts[0], 0);
      setTimeout(() => this.goTo(0), 500);
    },
    selectPayment(e) {
      this.payment = e;
      this.goToForm(2);
    },
    goToForm(e) {
      this.step = e;
    },
    cartDonationValue(item, index) {
      if(!item) return;
      this.btnOtherValue = false;
      this.otherValue = false;
      this.itemSelected = item;
      if (item && item.fixedValue != null && !item.openValue) {
        this.cartItem.itemValue = item.fixedValue;
      } else if (
        item &&
        this.donationProductValue(item.suggestionValue) != "" &&
        !item.openValue
      ) {
        this.cartItem.itemValue = this.donationProductValue(
          item.suggestionValue
        );
      } else {
        this.otherValue = true;
        this.cartItem.itemValue = 0;
      }
      this.cartItem.donationProductId = item.id;
      this.cartItem.donationProduct = item;
      this.cartItem.donationProduct.currentQuantity = 1;
      this.cartItem.project = item.project;
      this.cartItem.projectId = item.project.id;
      this.project = item.project;
      this.goTo(index);
    },
    selectOtherValue() {
      this.btnOtherValue = true;
      this.otherValue = true;
      this.itemSelected = new DonationProduct();
      this.goTo(this.donationProducts.length);
    },
    donationProductValue(value) {
      let matches = String(value).match(/[\d.,]+/g);
      if (!matches || matches.length == 0) return "";
      let ret = matches[0].replace(".", "").replace(",", ".");
      return parseFloat(ret);
    },
    next() {
      let slick = document.querySelector(".widget-slick");
      let width = document.querySelector(".widget-slick > div").offsetWidth;
      let actualIndexItem = this.donationProducts.indexOf(this.itemSelected);

      slick.scrollLeft = slick.scrollLeft + width;

      if (this.donationProducts.length > actualIndexItem + 1) actualIndexItem++;
      else actualIndexItem = 0;
      this.cartDonationValue(
        this.donationProducts[actualIndexItem],
        actualIndexItem
      );
    },
    prev() {
      let slick = document.querySelector(".widget-slick");
      let width = document.querySelector(".widget-slick > div").offsetWidth;
      let actualIndexItem = this.donationProducts.indexOf(this.itemSelected);

      slick.scrollLeft = slick.scrollLeft - width;

      if (actualIndexItem - 1 >= 0) actualIndexItem--;
      else actualIndexItem = this.donationProducts.length - 1;
      this.cartDonationValue(
        this.donationProducts[actualIndexItem],
        actualIndexItem
      );
    },
    prevButtons() {
      let slick_buttons = document.querySelector(".widget-values");
      if(slick_buttons) {
        slick_buttons.scrollLeft = slick_buttons.scrollLeft -= 100
      }
      this.prev();
    },
    nextButtons() {
      let slick_buttons = document.querySelector(".widget-values");
      if(slick_buttons) {
        slick_buttons.scrollLeft = slick_buttons.scrollLeft += 100
      }
      this.next();
    },
    checkScroll(e) {
      let slick = document.querySelector(".widget-slick");
      let divs = document.querySelectorAll(".widget-slick .widget-header");
      let id;
      let left = slick.scrollLeft;
      let slick_buttons = document.querySelector(".widget-values");
      let actualIndexItem = this.donationProducts.indexOf(this.itemSelected);
      var _this = this;

      if (this.timer !== null) {
        clearTimeout(this.timer);
      }

      // return false;
      this.timer = setTimeout(function () {
        for (let i = 0; i < divs.length; i++) {
          if (divs[i].offsetLeft >= left && divs[i].offsetLeft <= left + 5) {
            actualIndexItem = i;
          }
        }
        id = divs[actualIndexItem].getAttribute("data-id");
        let btn = document.querySelector(`button[data-value-id="${id}"]`);
        if (btn) {
          slick_buttons.scrollLeft = btn.offsetLeft - 70;
          _this.cartDonationValue(
            _this.donationProducts[actualIndexItem],
            actualIndexItem
          );
        }
        if (divs[actualIndexItem].querySelector('.widget-header-input input')) divs[actualIndexItem].querySelector('.widget-header-input input').focus()
      }, 150);
    },
    goTo(index) {
      let slick = document.querySelector(".widget-slick");
      let width = "";
      if (document.querySelector(".widget-slick > div")) {
        width = document.querySelector(".widget-slick > div").offsetWidth;
      }
      slick.scrollLeft = width * index;
    },
    prevBtns() {
      document.querySelector('.widget-values').scrollLeft -= 130
    },
    nextBtns() {
      document.querySelector('.widget-values').scrollLeft += 130
    },
    openDescription(e) {
      e.target.classList.toggle("open");
      e.target.parentElement
        .querySelector(".widget-header-description")
        .classList.toggle("active");
    },
    setFocused(e, focus) {
      let parent = e.target.parentElement.parentElement.parentElement;
      if (focus) {
        parent.classList.add('focused')
      } else {
        parent.classList.remove('focused')
      }
    }
  },
};
</script>
<style>
.widget-slick {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}
.widget-slick > div {
  flex: none;
  width: 100%;
  scroll-snap-align: start;
}
.widget-slick::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.widget-slick .next,
.widget-slick .prev {
  position: absolute;
  color: white;
  font-size: 15px;
  z-index: 9;
  background: rgb(var(--theme-primaria));
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -1rem;
}
.widget-slick .prev {
  left: 1rem;
}
.widget-slick .next {
  right: 1rem;
}
.widget-header-description {
  font-size: 16px;
  opacity: 0;
  height: 0px;
  transition: all 0.3s;
  overflow: hidden;
  display: block;
}
.widget-header-description.active {
  height: auto;
  opacity: 1;
}
.widget button.widget-btn {
  background-color: rgba(var(--theme-primaria)) !important;
}
.btn-control {
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0 !important;
  background: #f5f6fa;
  font-size: 20px;
  color: #4C4D4F;
}
</style>